import { template as template_4f72d6e1518a4bd496e9a6be0d7c4d13 } from "@ember/template-compiler";
const WelcomeHeader = template_4f72d6e1518a4bd496e9a6be0d7c4d13(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
