import { template as template_cad4b5636ce44e748602b15e10c81e22 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_cad4b5636ce44e748602b15e10c81e22(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_cad4b5636ce44e748602b15e10c81e22(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_cad4b5636ce44e748602b15e10c81e22(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
