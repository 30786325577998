import { template as template_4f506fc9104543c7bf35ae837e65d52b } from "@ember/template-compiler";
const FKLabel = template_4f506fc9104543c7bf35ae837e65d52b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
