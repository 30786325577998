import { template as template_babbb8a71e4a4ca08e6b6e50047456d6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_babbb8a71e4a4ca08e6b6e50047456d6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
